#footer{
  
    background: $cor1;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px;
    
    

    h1{
        color: $cor2;
        font-size: 50px;   
        text-align: center;
        margin: 0px 0px 40px 0px;
    }
    #marcafooter{
        img{
            max-height: 150px;
        }
    }
    #endereco{
        padding-top: 30px;
        p{
            margin: 0px;
            color: #fff;
        }
        .destaque{
            color: $cor2;
        }
    }
    #desenvolvidor{
        text-align: center;
        padding-top: 30px;
        p{
            margin: 0px 0px 10px 0px;
            color: #fff;
        }
        img{
            display: inline-block;
            width: 60px;
        }
    }
}

@media only screen and (max-width: 996px) {
    #footer{
  
        background: $cor1;
        padding-top: 0px;
        padding-bottom: 0px;
        margin: 0px;
        
        #marcafooter{
            img{
                width: 100%;
            }
        }
        #endereco{
            padding-top: 30px;
            text-align: center;
            .detSpan{
                display: block;
                width: 100%;
            }
            p{
                margin: 0px;
                color: #fff;
            }
            .destaque{
                color: $cor2;
            }
        }
    }
}