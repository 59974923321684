#pessoal{
 
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 0px !important;
    #conteudo-pessoal{
        
        height: 370px;
        position: relative;
        .bgParcial{
            position: absolute;
            background: #e5e5e5;
            left: 0px;
            width: calc(100% - 40px);
            height: 100%;
            z-index: -1;
        }
        
        #fotosLocalDr{
            
            width: 100%;
            margin-top: 20px;
            max-height: 330px;
            overflow: hidden;
            img{
                 width: 100%;
            }

            
        }
        h2{
            color: $cor1;
            font-size: 24px; 
            font-weight: bold;
            margin: 24px 0 0 0;
        }
        p{
            font-size: 14px;
            color: #666666;
        }
    }
}

@media only screen and (max-width: 996px) {
    #pessoal{
         #conteudo-pessoal {
            height: 100%;
            position: relative;

             
                .bgParcial{
                    position: absolute;
                    background: #e5e5e5;
                    left: 0px;
                    width: calc(100%);
                    height: 100%;
                    z-index: -1;
                }
        }
    }
}
