#sidebar-right{
    // background: #f0f4d5;
    padding: 0px 0;
     .sidebar-area{
         
         .widget{
             .sidebar-text{
                 padding: 10px 0;
                 color: #fff;
                 background: $cor1;
                 font-size: 18px;
                 margin: 0px !important;
             }
             .divider{
                 display: none;
             }
             ul{
                margin: 0px 0px 10px 0px;
                
                li{
                    a{
                        display: block;
                        padding: 10px 5px;
                        font-size: 14px;
                        background: rgba($color: #000000, $alpha: .05);
                        @extend .transicao;
                    }
                    a:hover{
                        padding: 10px 5px;
                        font-size: 14px;
                        background: rgba($color: $cor2, $alpha: .8);
                        color: #fff;
                        @extend .transicao;
                    
                    }
                }
                
             }
         }
     }

}