#sigarede{
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 0px !important;
    h2{
        color: $cor2;
        font-size: 25px; 
        margin: 14px 0 20px 0;
        text-align: center;

    }
    h3{

        color: $cor2;
        font-size: 24px; 
        font-weight: bold;
        margin: 24px 0 0 0;
        font-style:italic;
        text-align: right;
    }
    
    #postagem{
        #postagemi{
            margin-top: 20px;
        }
        .postagens{
            img{
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 996px) {
    #sigarede{
        padding-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 0px !important;
        h2{
            color: $cor2;
            font-size: 20px; 
            margin: 14px 0 0 0;
        }
        h3{
            color: $cor2;
            font-size: 14px; 
            font-weight: bold;
            margin: 24px 0 0 0;
            font-style:italic;
            text-align: right;
        }
    }
}