#tratamentos{
    
    background: #f0f4d5;
    padding-top: 50px;
    padding-bottom: 50px;
    h1{
        color: $cor2;
        font-size: 50px;   
        text-align: center;
        margin: 0px 0px 40px 0px;
        font-weight: 400;
    }
    .d1{
        border-right: 2px solid #f0f4d5;
    }
    .d2{
        border-left: 2px solid #f0f4d5;
    }
    .doencasdestaque{

        max-height: 400px;
        overflow: hidden;
        position: relative;
        background: $cor1;
        @extend .transicao;

       img{
           width:100%;
           opacity: .65;
           @extend .transicao;
       }
       p{
            position: absolute;
            bottom: 42px;
            left: 10px;
            font-size: 20px;
            color: #fff;
            @extend .transicao;
       }
       h2{
        @extend .transicao;
            position: absolute;
            bottom: 10px;
            left: 10px;
            color: white;
            margin: 0px;
            font-weight: bold;
            font-size: 50px;
        }
    }
    .doencasdestaque:hover{
        @extend .transicao;
        img{
            opacity: 1;
        @extend .transicao;

        }
        h2{
            position: absolute;
            bottom: 10px;
            left: 10px;
            color: $cor1;
            margin: 0px;
            font-weight: bold;
            font-size: 50px;
        @extend .transicao;

        }
        p{
            position: absolute;
            bottom: 42px;
            left: 10px;
            font-size: 20px;
            color: $cor2;
        @extend .transicao;

       }
     }
    #btns-tratamento{
        padding: 30px 0;
        text-align: center;
        .btn{
            margin:  5px;
        }
    } 
}

@media only screen and (max-width: 996px) {
    #tratamentos{
  
        background: #f0f4d5;
        padding-top: 50px;
        padding-bottom: 50px;
        h1{
            color: $cor2;
            font-size: 30px;   
            text-align: center;
            margin: 0px 0px 40px 0px;
            font-weight: 400;
        }
        .doencasdestaque{
            max-height: 400px;
            overflow: hidden;
            position: relative;
           img{
               width:100%;
               opacity:.6;
           }
           p{
                position: absolute;
                bottom: 42px;
                left: 10px;
                
                font-size: 20px;
               color: $cor2;
           }
           h2{
                position: absolute;
                bottom: 10px;
                left: 10px;
                color: white;
                margin: 0px;
                font-weight: bold;
                font-size: 40px;
            }
        }
        
        #btns-tratamento{
            padding: 30px 0;
            text-align: center;
            .btn{
                margin:  5px;
            }
        } 
        .d1{
            border-right: none;
        }
        .d2{
            border-left: none;
        }
    }
}