.body-link{
    display: none;
    
}

#blog-container{
    margin-top: 0px;

    #parte{
        margin-top: 20px;
    }
    .body-link{
        font-size: 15px !important;
    }
    .entry-date{
        font-size: 12px !important;

    }
    .entry-header{
        // height: 30px;
    }
    .flow-text{
        padding-top: 10px;
        h2{
            font-size: 16px;

        }
        p{
            margin: 0px !important;
            font-size: 12px;
        }
    }
    .listaFooter{
        display: none;
        height: 20px;
    }
    .post{
        margin-bottom: 20px;
    }
    
    .thumbImgCont{
        overflow: hidden;
        max-height: 200px;
        margin-bottom: 10px;
        @extend .transicao;

        img{
            width: 100%;
            height: auto !important;
        }
    }
    .thumbImgCont:hover{
        opacity: .6;
        @extend .transicao;
    }
   
    .thumbImgListPostagem{
        position: relative;
        .thumbImgCont{
            margin-bottom: 0px;
            img{
                float: left;
            }
        }
        header{
            position: absolute;
            bottom: 0px;
            background: rgba($color: #000000, $alpha: .5);
            width: 100%;
            padding: 5px;
            color: #fff;
           
            a{
                color: #fff;
            }
            
        }
    }

}