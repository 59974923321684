#convenios{
  
    background: #fff;
    padding-top: 50px;
    padding-bottom: 50px;
    h1{
        color: $cor2;
        font-size: 20px;   
        text-align: center;
        margin: 0px 0px 40px 0px;
    }
    #convenios-lista{
        ul{
            text-align: center;
            li{
                display: inline-block;
                padding: 0 20px;
                img{
                    height: 90px;
                }
            }
        }
    }
}