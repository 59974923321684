#aclinica{
 
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 0px !important;
    #conteudo-aclinica{
        
        min-height: 370px;
        position: relative;
        .bgParcial{
            position: absolute;
            background: #e5e5e5;
            left: 40px;
            width: calc(100% - 40px);
            height: 100%;
            z-index: -1;
        }
        
        #fotosLocal{
            
            width: 370px;
            margin-top: 20px;
            img{
                 width: 100%;
            }

            .carousel{
                height: 330px !important;
                .indicators {
                    
                    position: absolute;
                    text-align: center;
                    left: 0;
                    right: 0;
                    margin: 0;
                    bottom: -30px;

                    .indicator-item{
                        background-color: #bababa;
                        @extend .transicao;
                    }
                    .indicator-item:hover{
                        background-color: #b6c62c;
                        @extend .transicao;

                    }
                    .indicator-item.active{
                        background-color: #b6c62c;
                        @extend .transicao;

                    }
                } 
            } 
        }
        h2{
            color: $cor1;
            font-size: 24px; 
            font-weight: bold;
            margin: 24px 0 0 0;
        }
        p{
            font-size: 14px;
            color: #666666;
        }
    }
}

@media only screen and (max-width: 1124px) {

#aclinica {

    #conteudo-aclinica{
        padding: 0px !important;
        #fotosLocal{
            width: 100% !important;
            .carousel {
                height: 245px !important;
            }
        }
    }
}


}
    @media only screen and (max-width: 996px) {
     
#aclinica {

    #conteudo-aclinica{
        padding: 0px !important;
        #fotosLocal{
            width: 100% !important;
            .carousel {
                height: 245px !important;
            }
        }
    }
    .bgParcial {
        position: absolute;
        background: #e5e5e5;
        left: 0px !important;
        width: calc(100% - 0px) !important;
        height: calc(100% - 20px);
        z-index: -1;
        top: 30px;
    }
}

}
