$cor1: #006b33; 
$cor2: #b6c62c; 
$cor3: #f0f4d5;
$cor4: #666666; 

.wp1s-main-wrapper .bx-wrapper .bx-pager {
    bottom: -24px;
}
.bx-wrapper .bx-viewport{
    background: transparent !important;
}
.wp1s-slider-wrapper .wp1s-slider-container{
    background: transparent !important;

}

.wp-block-column{
    ul{
        list-style-type: disc !important;
        padding-left: 20px;
        li{
            padding: 5px 0;
            list-style-type: disc;
    color: $cor4;

        }
    }
}
body{
    font-family: 'Roboto Condensed', sans-serif;
}
p{
    font-family: 'Roboto Condensed', sans-serif;
    color: $cor4;
}
h1,h2{
    font-weight: bold;
    color: $cor1;
    font-size: 30px;
    margin: 4px 0;
}
a{
    color: $cor1;
}
h3{
    font-style:italic;
    color: $cor1;
    font-size: 26px;
}

.listPosts{
    font-size: 20px !important;
}

.btn-theme1{
    background: $cor2;
}

.btn-theme1:hover{
    background: $cor2;
}
.btn-theme2{
    border: 1px solid $cor1;
    background: transparent;
    color: $cor1;
}
.btn-theme2:hover{
    border: 1px solid $cor1;
    background: transparent;
    color: $cor1;
}
.transicao{
    transition:all .3s linear !important;
    -webkit-transition:all .3s linear !important;
    -moz-transition:all .3s linear !important;
}


 

#header{
    background: #fff;
    height: 80px;
    width: 100%;
    position: relative;
    margin: 0px !important;
    #marcab{
        padding-top: 10px;
        padding-left: 1.5rem;
        #marca{
            height: 60px;
        }
    }
    #nav{
        position: relative;
        padding: 0px;
        height: 100%;

        ul{
            margin: 0px;
            padding: 0px;
            height: 100%;
            float: right;
            li{
                font-size: 12px;
                font-weight: bold;
                display: inline-block;
                height: 100%;
                position: relative;
                padding: 0 5px;
                a{
                    text-transform: uppercase;
                    color: $cor1;
                    @extend .transicao;
                    line-height: 70px;
                }
                a:hover{
                    color: $cor2;
                    @extend .transicao;
                }
            }
            li::after{
                background: #fff;
                height: 6px;
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 10px;  
                content: "";    
                @extend .transicao;   
                left: 50%;
                margin-left: -5px;           
            }
            li.current-menu-item::after{
                background: $cor2;
                height: 6px;
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 30px;  
                content: "";   
                @extend .transicao; 
                left: 50%;
                margin-left: -15px;             
            }
            li:hover::after{
                background: $cor2;
                height: 6px;
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 30px;  
                content: "";   
                @extend .transicao;  
                left: 50%;
                margin-left: -15px;            
            }
        }
    }

    #redes-sociais{
        ul{
            margin: 0px;
            padding: 0px;
            height: 100%;
            float: right;
            li{
                display: inline-block;
                line-height: 80px;
                text-align: center;
                padding: 0 15px;
                img{
                    width: 25px;
                    @extend .transicao;
                }
                img:hover{
                    opacity: .7;
                    width: 25px;
                    @extend .transicao;
                }
            }
        }
    }
}

#slide-outBTN{
    line-height: 80px;
    cursor: pointer;
    color: $cor2;
    font-size: 35px !important;
}

#slide-out{
    #marcaSidnav{
        text-align: center;
        img{
            width: 70%;
            margin: 15px 0 0 0;
        }
    }
    #redesSidNav{
        width: 100%;
        text-align: center;
        .redesSid{
            padding: 0 5px;
            img{
                width: 30px;
            }
        }
    }
}


.barratitulo{
  background: $cor3;
  height: 100px;
  h1{
      line-height: 100px;
      margin: 0px;
      color: $cor2;
  }
}



.btn-more{
    padding: 0 10px !important;
    font-size: 12px !important;
    line-height: 30px;
    height: 29px;
}


@import "home/banner";
@import "home/aclinica";
@import "home/tratamentos";
@import "home/pessoal";
@import "home/sigarede";
@import "home/convenios";
@import "home/footer";
@import "blog/blog";
@import "blog/comments";
@import "blog/blog-page";
@import "blog/sidebar-area";


